import { UniqueDirectiveNamesRule } from 'graphql';
import * as fb from "@/firebase/index.js";
import router from '../../router';

export default {
  state: {
    kioskProfile: {},
    kioskId: undefined,
    count: 0,
    kioskModules: [],
    dataloadInit: false,
    qnaEndpointEN: {},
    qnaEndpointES: {},
    qnaEndpointMD: {},
    qnaEndpointPB: {},
    qnaEndpointAS: {},
  },
  getters: {
    getKioskId: state => state.kioskId,
    getKioskProfile: state => state.kioskProfile,
    getCMSlink: state => state.kioskProfile.data().cmsLink,
    getQnaEndpointEN: state => state.qnaEndpointEN,
    getQnaEndpointES: state => state.qnaEndpointES,
    getQnaEndpointMD: state => state.qnaEndpointMD,
    getQnaEndpointPB: state => state.qnaEndpointPB,
    getQnaEndpointAS: state => state.qnaEndpointAS,
    getSitepalID: state => state.kioskProfile.data().sitepalId,
    getAvatarID: state => state.kioskProfile.data().avatarId,
    getKioskType: state => state.kioskProfile.data().email.split('@')[0],
    getKioskModules: state => state.kioskModules,
    getDataLoadInit: state => state.dataloadInit
  },
  mutations: {
    setKioskId(state, kioskId) {
      state.kioskId = kioskId;
    },
    setKioskProfile(state, profile) {
      state.kioskProfile = profile;
    },
    setKioskModules(state, module) {
      state.kioskModules.push(module)
    },
    setDataloadInit(state, status) {
      state.dataloadInit = status
    },
    setQnaEndpointEN(state, qnaObj) {
      console.log('qnaObj: ', qnaObj)
      state.qnaEndpointEN.enable = qnaObj.enable
      state.qnaEndpointEN.kbName = qnaObj.kbName
    },
    setQnaEndpointES(state, qnaObj) {
      state.qnaEndpointES.enable = qnaObj.enable
      state.qnaEndpointES.kbName = qnaObj.kbName
    },
    setQnaEndpointMD(state, qnaObj) {
      state.qnaEndpointMD.enable = qnaObj.enable
      state.qnaEndpointMD.kbName = qnaObj.kbName
    },
    setQnaEndpointPB(state, qnaObj) {
      state.qnaEndpointPB.enable = qnaObj.enable
    },
    setQnaEndpointAS(state, qnaObj) {
      state.qnaEndpointAS.enable = qnaObj.enable
    }
  },
  actions: {
    login({ dispatch }, loginObj) {
      return new Promise((resolve, reject) => {
        fb.auth.signInWithEmailAndPassword(loginObj.email, loginObj.password)
          .then(cred => {
            console.log('Kiosk loggedIn successfully:', cred.user.uid);
            dispatch('fetchKioskProfile', cred.user);
            resolve('Successfully LoggedIn');
            router.push('/loadData')
          })
          .catch(error => {
            reject(error.message);
          })
      })
    },
    fetchKioskProfile({ commit, dispatch, state, getters }, kiosk) {
      fb.kioskCollection.doc(kiosk.uid)
        .onSnapshot(kioskData => {
          console.log('Kiosk Info: ', kioskData.data())
          if (kioskData.data() === undefined) {
            dispatch('logout')
          } else {
            // Setting Kiosk ID
            commit('setKioskId', kiosk.uid);
            // Setting Kiosk Data 
            commit('setKioskProfile', kioskData);

            // Checking refresh of the kiosk from Dahboard
            dispatch('autoRefreshKiosk', kioskData)

            // To initiate data load from the data fetched from the firebase.
            if (!getters.getDataLoadInit) {
              commit('setDataloadInit', true)
              dispatch('initiateDataPull', kioskData)
            }
          }
        })
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        fb.auth.signOut()
          .then(() => {
            commit('setKioskId', undefined);
            commit('setKioskProfile', {});
            commit('setDataloadInit', false)
            resolve('Successfully Logged Out');
            location.reload();
          })
          .catch(error => {
            reject(error.message);
          })
      })
    },
    manageKioskPresence({ state }, kiosk) {
      var kioskStatusDatabaseRef = fb.firebase.database().ref('/status/' + kiosk.uid);
      var isOfflineForDatabase = {
        state: 'Unavailable',
        // last_changed: fb.firebase.database.ServerValue.TIMESTAMP,
      };

      var isOnlineForDatabase = {
        state: 'Available',
        // last_changed: fb.firebase.database.ServerValue.TIMESTAMP,
      };

      fb.firebase.database().ref('.info/connected').on('value', function (snapshot) {
        // If we're not currently connected, don't do anything.
        if (snapshot.val() === false) return;

        kioskStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function () {
          kioskStatusDatabaseRef.set(isOnlineForDatabase);
        });
      });
    },
    sendEmail({ state }, emailData) {
      console.log(state.kioskId);
      return new Promise((resolve, reject) => {
        const mailObj = {
          to: emailData.address,
          message: {
            subject: emailData.subject,
            html: emailData.content,
          }
        }
        fb.mailCollection.add(mailObj)
          .then(() => {
            resolve('Email sent successfully')
          })
          .catch(error => {
            reject(error.message)
          })
      })
    },
    sendQnaData({ state, commit }, data) {
      fb.qnaCollection.add(data)
        .then(response => {
          console.log(state.kioskId);
          //commit('setUserQnaRequest', response.id);
          console.log("QNA analytics added successfully")
        })
        .catch(error => {
          console.log(error.message);
        })
    },
    autoRefreshKiosk({ state }, kiosk) {
      fb.kioskCollection.doc(kiosk.id).onSnapshot(snapshot => {
        console.log('Snapshot of Kiosk info: ', snapshot.data())
        if (snapshot.data().autoRefresh) {
          fb.kioskCollection.doc(kiosk.id).update({
            autoRefresh: false
          }).then(() => {
            location.reload();
          })

        }
      })
    },
    updateAutoReferesh({ state }, kiosk) {
      console.log('Update Auto Refresh: ', kiosk)
      if (kiosk.data().autoRefresh) {
        //
      }
    }
  }
}
